import React from 'react'
import { graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Img from 'gatsby-image'

// Libraries
import _ from 'lodash'

// Components
import { BannerAlt } from 'components/Elements'
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import RequestOffer from 'components/RequestOffer'

// Styles
import 'styles/templates/TestimonialsTemplate.scss'

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    page: wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      path
      acf {
        title: banner_title
        image: banner_image {
          localFile {
            childImageSharp {
              fluid(quality: 100, maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
              fixed(width: 1024, toFormat: JPG) {
                src
              }
            }
          }
        }
      }
      description: content
      yoastMeta: yoast_meta {
        name
        content
        property
      }
    }
    testimonials: allWordpressWpTestimonials(
      sort: { fields: date, order: DESC }
    ) {
      edges {
        node {
          id: wordpress_id
          title
          acf {
            description
            image {
              localFile {
                childImageSharp {
                  fluid(quality: 100, maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

const TestimonialsTemplate = ({
  data: {
    page: {
      path,
      acf: { title, image },
      description,
      yoastMeta,
    },
    testimonials: { edges: testimonials },
  },
}) => {
  return (
    <Layout>
      <SEO yoast={{ meta: yoastMeta }} pathname={path} image={image.localFile.childImageSharp.fixed.src} article />
      <div className="testimonials-template">
        <BannerAlt>
          <BackgroundImage
            className="banner-background-image"
            fluid={image.localFile.childImageSharp.fluid}
          />
          <div className="banner-content row pt-5">
            <div className="col-lg-6 pt-5 text-center text-lg-left">
              <h1 className="mt-5">
                <div dangerouslySetInnerHTML={{ __html: title }} />
              </h1>
            </div>
            <div className="col-lg-6" />
          </div>
        </BannerAlt>

        <div className="container text-center pt-5 px-lg-5">
          <div
            className="page-content"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>

        <div className="mt-5 pt-lg-0 pt-5">
          <div className="pt-lg-0 pt-5">
            {_.map(testimonials, ({ node }, index) => {
              if (index % 2 === 0) {
                return <TestimonialOdd key={index} node={node} />
              } 
                return <TestimonialEven key={index} node={node} />
              
            })}
          </div>
        </div>

        <div>
          <RequestOffer isRed />
        </div>
      </div>
    </Layout>
  )
}

const TestimonialEven = ({
  node: {
    id,
    title,
    acf: { description, image },
  },
}) => (
  <div
    className="testimonials-template-testimonial testimonials-template-testimonial-even py-5"
    key={id}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-5 px-5 d-block d-lg-none">
          <div className="px-5 h-100 d-flex justify-content-center align-items-center">
            <Img
              className="testimonials-template-testimonial-image"
              fluid={image.localFile.childImageSharp.fluid}
            />
          </div>
        </div>
        <div className="col-lg-7 mt-lg-0 mt-5">
          <h3 className="text-uppercase">{title}</h3>
          <div
            className="mt-3 font-family-secondary text-justify font-size-sm font-family-secondary"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
        <div className="col-lg-5 px-5">
          <div className="px-5 h-100 d-none d-lg-flex justify-content-center align-items-center">
            <Img
              className="testimonials-template-testimonial-image"
              fluid={image.localFile.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

const TestimonialOdd = ({
  node: {
    id,
    title,
    acf: { description, image },
  },
}) => (
  <div
    className="testimonials-template-testimonial testimonials-template-testimonial-odd py-5"
    key={id}
  >
    <div className="container">
      <div className="row">
        <div className="col-lg-5 px-5">
          <div className="px-5 h-100 d-flex justify-content-center align-items-center">
            <Img
              className="testimonials-template-testimonial-image"
              fluid={image.localFile.childImageSharp.fluid}
            />
          </div>
        </div>
        <div className="col-lg-7 mt-5 mt-lg-0">
          <h3 className="text-uppercase">{title}</h3>
          <div
            className="mt-3 font-family-secondary text-justify font-size-sm font-family-secondary"
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </div>
      </div>
    </div>
  </div>
)

export default TestimonialsTemplate
